import React from 'react'
import { Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import WrapperWithEdge from 'components/WrapperWithEdge'
import TestimonialWithIllustration from 'components/TestimonialWithIllustration'

const Testimonial = () => (
  <WrapperWithEdge edgeBackgroundColor={COLOR.WHITE} direction="down">
    <Wrapper container>
      <TestimonialWithIllustration />
    </Wrapper>
  </WrapperWithEdge>
)

export default Testimonial
