import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { screenMax, screenMin } from '@farewill/ui/helpers/responsive'
import { Button, Wrapper } from '@farewill/ui'

import QuestionAndAnswer from './QuestionAndAnswer'

const StyledButton = styled(Button.Underline)`
  color: ${COLOR.BLACK};
`

type Layout = 'one-col' | 'two-col'

const StyledList = styled.dl<{ $hideBottomBorder: boolean; $layout: Layout }>`
  ${({ $layout }) =>
    $layout === 'two-col' &&
    css`
      ${screenMin.l`
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0 ${GTR.L};

        > *:nth-child(2) {
          border-top: solid 1px ${COLOR.GREY.LIGHT};
        }
      `}
    `}

  > *:first-child {
    border-top: solid 1px ${COLOR.GREY.LIGHT};
  }

  > * {
    border-bottom: solid 1px ${COLOR.GREY.LIGHT};
  }

  ${({ $hideBottomBorder }) =>
    $hideBottomBorder &&
    screenMax.s`
    && {
      margin-bottom: -${GTR.L};
    }

    > * {
      &:last-of-type {
        border-bottom: none
      }
    }
  `}
`

interface QuestionListProps {
  amount?: number
  hideBottomBorder?: boolean
  hideAdditionalQuestions?: boolean
  questions: GatsbyTypes.ContentfulFaqSection['questions']
  className?: string
  layout?: Layout
}

const QuestionList = ({
  amount = 5,
  hideBottomBorder = false,
  hideAdditionalQuestions = false,
  questions,
  className,
  layout = 'one-col',
}: QuestionListProps): React.ReactElement => {
  const [isHidden, setIsHidden] = useState(true)
  const [questionsList, setQuestionsList] = useState(questions)

  useEffect(() => {
    if (hideAdditionalQuestions && isHidden) {
      setQuestionsList(questionsList?.slice(0, amount))
    }
    if (hideAdditionalQuestions && !isHidden) {
      setQuestionsList(questions)
    }
  }, [isHidden])

  return (
    <>
      <StyledList
        className={className}
        $hideBottomBorder={hideBottomBorder}
        $layout={layout}
      >
        {questionsList &&
          questionsList.map((question) => (
            <QuestionAndAnswer
              id={question?.id}
              key={question?.id}
              title={question?.title}
              content={question?.richContent}
            />
          ))}
      </StyledList>
      {hideAdditionalQuestions && questions && questions.length > amount && (
        <Wrapper>
          <StyledButton
            flush
            onClick={() => setIsHidden((prevState) => !prevState)}
          >
            {isHidden ? 'View more questions' : 'View less questions'}
          </StyledButton>
        </Wrapper>
      )}
    </>
  )
}

export default QuestionList
