import React from 'react'
import PropTypes from 'prop-types'

const BorderedSteps = ({ children }) => {
  const childrenCount = children.length
  return (
    <>
      {children.map(({ props, type }, i) =>
        React.createElement(type, {
          ...props,
          key: i, // eslint-disable-line react/no-array-index-key
          index: i + 1,
          alternate: !!(i % 2),
          showDivider: i < childrenCount - 1,
        })
      )}
    </>
  )
}

BorderedSteps.propTypes = {
  children: PropTypes.node.isRequired,
}

export { default as BorderedStep } from './BorderedStep'

export default BorderedSteps
