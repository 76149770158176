import React from 'react'
import { Button, H, Wrapper, P, ButtonGroup } from '@farewill/ui'
import styled from 'styled-components'
import { COLOR, FONT } from '@farewill/ui/tokens'
import { graphql, useStaticQuery } from 'gatsby'
import { normalizeArray } from 'lib/graphql/normalize'

import {
  WHAT_TO_DO_WHEN_SOMEONE_DIES_TELEPHONE_NUMBER,
  WHAT_TO_DO_WHEN_SOMEONE_DIES_PDF_URL,
} from 'config'

import ExternalLink from 'components/ExternalLink'
import TelephoneNumber from 'components/TelephoneNumber'
import BorderedSteps, { BorderedStep } from 'components/BorderedSteps'
import QuestionList from 'components/FrequentlyAskedQuestions/QuestionList'

const tellUsOnceLink =
  'https://www.gov.uk/after-a-death/organisations-you-need-to-contact-and-tell-us-once'
const settldLink = 'https://www.settld.care/'
const englandWalesRegisterLink = 'https://www.gov.uk/register-offices'
const scotlandRegisterLink = 'https://www.nrscotland.gov.uk/registration'

const StyledExternalLink = styled(ExternalLink)`
  font-weight: ${FONT.WEIGHT.BOLD};
`

const Steps = () => {
  const data = useStaticQuery(graphql`
    query {
      faqSections: allContentfulFaqSection(
        filter: {
          entryTitle: {
            in: [
              "What to do when someone dies - Section 1"
              "What to do when someone dies - Section 2"
              "What to do when someone dies - Section 3"
            ]
          }
        }
      ) {
        edges {
          node {
            entryTitle
            ...FaqSectionFields
          }
        }
      }
    }
  `)

  const faqSections = normalizeArray(data.faqSections)
  const sectionByTitle = (title) =>
    faqSections.find((section) => section.title === title)

  const sectionOneFaq = sectionByTitle('Register the death')
  const sectionTwoFaq = sectionByTitle('Arrange the funeral')
  const sectionThreeFaq = sectionByTitle('Apply for probate')

  return (
    <Wrapper background={COLOR.WHITE}>
      <BorderedSteps>
        {[
          <BorderedStep
            imagePath="illustrations/certificate-with-bow"
            imageWidth={320}
            centerAligned
            key="1"
          >
            <H size="M">Get a medical certificate</H>
            <P>
              The first thing you need to do after someone dies is get a medical
              certificate detailing the cause of death. If the person died in a
              hospital, this should have been given to you by a member of staff.
              If they died at home, you’ll need to call their GP to get a
              medical certificate.
            </P>
          </BorderedStep>,
          <BorderedStep imagePath="illustrations/blob-signing-will-2" key="2">
            <H size="M">Register the death</H>
            <P>
              The next step is to register the death and get a death
              certificate. This usually needs to be done within five days and
              only takes around 30 minutes, but it can be delayed if there’s an
              inquest into the death. Find your nearest register office in{' '}
              <StyledExternalLink href={englandWalesRegisterLink}>
                England and Wales
              </StyledExternalLink>{' '}
              or{' '}
              <StyledExternalLink href={scotlandRegisterLink}>
                Scotland
              </StyledExternalLink>
              .
            </P>
            {sectionOneFaq && (
              <QuestionList
                hideBottomBorder
                questions={sectionOneFaq.questions}
              />
            )}
          </BorderedStep>,
          <BorderedStep imagePath="illustrations/humanist-ceremony" key="3">
            <H size="M">Arrange the funeral</H>
            <P margin={[0, 0, 'M']}>
              If your loved one made a will, they may have included funeral
              wishes to help you plan a meaningful farewell. If you want to
              speak to a specialist about your options or need to use money from
              the estate to pay for the funeral, please give our cremation team
              a call.
            </P>
            <Wrapper margin={[0, 0, 'L']}>
              <TelephoneNumber
                telephoneNumber={WHAT_TO_DO_WHEN_SOMEONE_DIES_TELEPHONE_NUMBER}
                size="large"
                highlighted
              />
            </Wrapper>
            {sectionTwoFaq && (
              <QuestionList
                hideBottomBorder
                questions={sectionTwoFaq.questions}
              />
            )}
          </BorderedStep>,
          <BorderedStep imagePath="illustrations/experts" key="4">
            <H size="M">Notify people and organisations</H>
            <P>
              By using the government’s Tell Us Once service, you can notify
              multiple government departments of your loved one’s death,
              including the Passport Office, HMRC and the DVLA. You can also use
              Settld’s free death notification service to inform all companies
              in one go.
            </P>
            <P margin={[0, 0, 'M']}>
              We’ve also created a{' '}
              <ExternalLink href={WHAT_TO_DO_WHEN_SOMEONE_DIES_PDF_URL}>
                downloadable checklist
              </ExternalLink>{' '}
              highlighting other people and organisations you may need to speak
              to.
            </P>
            <ButtonGroup align="left" horizontalFromS leftToRight>
              <Button.Secondary
                tag={ExternalLink}
                href={tellUsOnceLink}
                strecth={false}
                wide={false}
              >
                Use Tell Us Once service
              </Button.Secondary>
              <Button.BorderedDark
                tag={ExternalLink}
                href={settldLink}
                stretch={false}
                wide={false}
              >
                Use Settld notification
              </Button.BorderedDark>
            </ButtonGroup>
          </BorderedStep>,
          <BorderedStep
            imagePath="illustrations/cropped/probate-process"
            key="5"
          >
            <H size="M">Apply for probate</H>
            <P>
              Before you can deal with the person’s estate and distribute assets
              to beneficiaries, you may need to apply for probate. If you think
              you might need probate, need help applying or want to speak to a
              specialist about your situation, please give our probate team a
              call.
            </P>
            <Wrapper margin={[0, 0, 'L']}>
              <TelephoneNumber
                telephoneNumber={WHAT_TO_DO_WHEN_SOMEONE_DIES_TELEPHONE_NUMBER}
                size="large"
                highlighted
              />
            </Wrapper>
            {sectionThreeFaq && (
              <QuestionList
                hideBottomBorder
                questions={sectionThreeFaq.questions}
              />
            )}
          </BorderedStep>,
        ]}
      </BorderedSteps>
    </Wrapper>
  )
}

export default Steps
