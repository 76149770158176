import React from 'react'
import styled from 'styled-components'
import { Button, P, Image, Wrapper, H } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'

import { WHAT_TO_DO_WHEN_SOMEONE_DIES_PDF_URL } from 'config'

import ExternalLink from 'components/ExternalLink'

const StyledImage = styled(Image)`
  margin-bottom: ${GTR.S};
`

const Checklist = () => (
  <Wrapper background={COLOR.STATE.WARNING_10}>
    <Wrapper centered container>
      <StyledImage
        path="illustrations/cropped/simple-printer-with-cat"
        width={150}
        widthFromM={200}
      />
      <H size="L" decorative>
        Free bereavement checklist
      </H>
      <P maxWidth={600} margin={[0, 'auto', 'L']}>
        Our legal and funeral specialists have put together a simple checklist
        to help you keep track of everything you need to do after someone dies.
      </P>
      <Button.Primary
        href={WHAT_TO_DO_WHEN_SOMEONE_DIES_PDF_URL}
        tag={ExternalLink}
      >
        Download checklist
      </Button.Primary>
    </Wrapper>
  </Wrapper>
)

export default Checklist
