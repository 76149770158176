import React from 'react'
import styled from 'styled-components'
import { Grid, P, Image, Wrapper } from '@farewill/ui'
import { BORDER, COLOR } from '@farewill/ui/tokens'
import { headingM } from '@farewill/ui/helpers/text'
import { screenMin } from '@farewill/ui/helpers/responsive'

import PreventOrphan from 'components/PreventOrphan'
import SimpleQuote from 'components/SimpleQuote'

const AVATAR_WIDTH = 70

const StyledCustomerQuote = styled(Grid)`
  border-radius: ${BORDER.RADIUS.S};
`

const StyledImageGridItem = styled(Grid.Item)`
  display: none;

  ${screenMin.l`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  `}
`

const StyledAttribution = styled(Wrapper)`
  display: flex;
  align-items: center;
`

const StyledImage = styled(Image)`
  transform: scaleX(-1);
`

const StyledAvatar = styled(Image)`
  transform: scaleX(-1);
  margin: -20px 10px -15px -5px;
  min-width: ${AVATAR_WIDTH}px;

  ${screenMin.l`
    display: none;
  `}
`

const StyledSimpleQuote = styled(SimpleQuote)`
  ${headingM}
`

const TestimonialWithIllustration = () => (
  <StyledCustomerQuote
    background={COLOR.STATE.WARNING_10}
    container
    containerPaddingTop="L"
    containerPaddingBottom="L"
    gap={0}
    gapFromL="L"
  >
    <Grid.Item spanFromL={10}>
      <StyledSimpleQuote decorative margin={[0, 0, 'M']}>
        <PreventOrphan>
          I entrusted Farewill with my mother’s cremation and could not be
          happier with my choice. They were efficiency itself right from the
          initial phone call and took care of absolutely everything. It was a
          massive weight off my shoulders.
        </PreventOrphan>
      </StyledSimpleQuote>
      <StyledAttribution>
        <StyledAvatar
          path="illustrations/blob-head-with-speech-line"
          width={AVATAR_WIDTH}
        />
        <P>J. McGowan, Farewill customer</P>
      </StyledAttribution>
    </Grid.Item>
    <StyledImageGridItem centered span={2}>
      <StyledImage
        path="illustrations/blob-head-with-speech-line"
        widthFromXL={160}
        width={150}
      />
    </StyledImageGridItem>
  </StyledCustomerQuote>
)

export default TestimonialWithIllustration
