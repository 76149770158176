import React from 'react'
import styled from 'styled-components'
import { ChevronDownIcon } from '@farewill/ui'
import { GTR, COLOR } from '@farewill/ui/tokens'

import DropdownButton from 'components/DropdownButton'
import PreventOrphan from 'components/PreventOrphan'
import RichContent from 'views/general/templates/components/RichContent'
import { useDropdown, ContentWrapper } from 'lib/hooks/useDropdown'

const StyledChevronIcon = styled(ChevronDownIcon)`
  position: relative;
  top: -3px; // Adjustment for optical alignment
`

const StyledQuestionAndAnswer = styled.div`
  position: relative;
  padding: ${GTR.S} 0;
`

const StyledDropdownButton = styled(DropdownButton)`
  width: 100%;
  text-align: left;
  font-weight: normal;
  color: ${COLOR.BLACK};
`

const StyledAnswerWrapper = styled(ContentWrapper)`
  margin: ${GTR.S} 0 0;
`

interface QuestionAndAnswerProps {
  title?: string
  content?: GatsbyTypes.Maybe<GatsbyTypes.ContentfulContentSectionRichContent>
  id?: string
}

const QuestionAndAnswer = ({
  title = '',
  content,
  id,
}: QuestionAndAnswerProps): React.ReactElement => {
  const { contentEl, contentHeight, isOpen, isTransitioning, triggerDropdown } =
    useDropdown()
  const ariaId = `faq-${id}`

  return (
    <StyledQuestionAndAnswer>
      <dt>
        <StyledDropdownButton
          isOpen={isOpen}
          onClick={() => triggerDropdown(isOpen)}
          stretch
          ariaControls={ariaId}
          ariaExpanded={isOpen}
          icon={<StyledChevronIcon />}
        >
          <PreventOrphan>{title}</PreventOrphan>
        </StyledDropdownButton>
      </dt>
      <StyledAnswerWrapper
        tag="dd"
        contentEl={contentEl}
        isOpen={isOpen}
        isTransitioning={isTransitioning}
        contentHeight={contentHeight}
        ariaId={ariaId}
      >
        {content && <RichContent data={content} />}
      </StyledAnswerWrapper>
    </StyledQuestionAndAnswer>
  )
}

export default QuestionAndAnswer
