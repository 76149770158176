import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Divider, Wrapper, Grid, Image, P } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import { smallCaps } from '@farewill/ui/helpers/text'

const StyledImageGridItem = styled(Grid.Item)`
  justify-self: center;
`

const StyledStep = styled(P)`
  ${smallCaps}
  margin-bottom: ${GTR.XXS};
`

const StyledGrid = styled(Grid)`
  ${({ $centerAligned }) => $centerAligned && `align-items: center;`}
`

const BorderedStep = ({
  children,
  index,
  alternate,
  centerAligned,
  imagePath,
  imageWidth,
  showDivider,
}) => (
  <Wrapper>
    <StyledGrid container $centerAligned={centerAligned}>
      <StyledImageGridItem
        spanFromM={6}
        spanFromL={4}
        startColumnFromM={alternate ? 7 : 1}
        startColumnFromL={alternate ? 8 : 1}
      >
        <Image
          path={imagePath}
          width={200}
          widthFromM={imageWidth}
          stretch
          margin={[0, 'auto']}
        />
      </StyledImageGridItem>
      <Grid.Item
        spanFromM={6}
        startColumnFromM={alternate ? 1 : 7}
        startColumnFromL={alternate ? 1 : 6}
      >
        <Wrapper>
          <StyledStep>Step {index}</StyledStep>
          {children}
        </Wrapper>
      </Grid.Item>
    </StyledGrid>
    {showDivider && (
      <Divider container containerPaddingTop={0} containerPaddingBottom={0} />
    )}
  </Wrapper>
)

BorderedStep.propTypes = {
  alternate: PropTypes.bool,
  children: PropTypes.node.isRequired,
  centerAligned: PropTypes.bool,
  imagePath: PropTypes.string.isRequired,
  imageWidth: PropTypes.number,
  index: PropTypes.number,
  showDivider: PropTypes.bool,
}

BorderedStep.defaultProps = {
  alternate: false,
  centerAligned: false,
  imageWidth: 350,
  index: 1,
  showDivider: true,
}

export default BorderedStep
