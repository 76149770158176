import React from 'react'
import styled from 'styled-components'
import { Image, Grid, Wrapper, H, P } from '@farewill/ui'
import {
  COLOR,
  FONT,
  GTR,
  BORDER_RADIUS,
  BREAKPOINT,
} from '@farewill/ui/tokens'

import { WHAT_TO_DO_WHEN_SOMEONE_DIES_TELEPHONE_NUMBER } from 'config'
import { formattedOpenHours, formattedOpenDays } from 'lib/time/formatting'
import { PROBATE } from 'lib/products/constants'

import BreakpointSwitch from 'components/BreakpointSwitch'
import PreventOrphan from 'components/PreventOrphan'
import TelephoneNumber from 'components/TelephoneNumber'

const StyleP = styled(P)`
  color: ${COLOR.BLACK};
  font-size: ${FONT.SIZE.L};
`

const StyledTelephoneWrapper = styled(Wrapper)`
  background-color: ${COLOR.WHITE};
  display: inline-block;
  padding: ${GTR.S};
  border-radius: ${BORDER_RADIUS.S};
  line-height: 1;
`

const StyledMobileImage = styled(Image)`
  display: block;
  margin: -10px auto;
`

const StyledWrapper = styled(Wrapper)`
  background-color: ${COLOR.ACCENT.PRIMARY};
  margin-top: -1px;
`

const Hero = () => (
  <StyledWrapper>
    <Grid container style={{ alignItems: 'center' }}>
      <Grid.Item spanFromM={6}>
        <H size="XL" decorative>
          <PreventOrphan>What to do when someone dies</PreventOrphan>
        </H>
        <StyleP>
          <PreventOrphan>
            Losing a loved one is an incredibly difficult thing to go through,
            so we’ve created this free guide to help make things a little bit
            easier.
          </PreventOrphan>
        </StyleP>
        <StyleP margin={[0, 0, 'M']}>
          <PreventOrphan>
            Prefer to talk to a specialist about your situation? We’re here from{' '}
            {formattedOpenDays(PROBATE)}, {formattedOpenHours(PROBATE)} to help.
          </PreventOrphan>
        </StyleP>
        <StyledTelephoneWrapper>
          <TelephoneNumber
            telephoneNumber={WHAT_TO_DO_WHEN_SOMEONE_DIES_TELEPHONE_NUMBER}
            size="large"
          />
        </StyledTelephoneWrapper>
      </Grid.Item>
      <Grid.Item
        spanFromM={6}
        startColumn={1}
        startColumnFromM={7}
        startRow={1}
        centeredFromM
      >
        <BreakpointSwitch
          breakAt={BREAKPOINT.S}
          aboveComponent={
            <Image
              path="illustrations/humanist-ceremony"
              width={250}
              widthFromM={500}
              stretch
            />
          }
          belowComponent={
            <StyledMobileImage
              path="illustrations/graveyard"
              width={250}
              stretch
            />
          }
        />
      </Grid.Item>
    </Grid>
  </StyledWrapper>
)

export default Hero
