import React from 'react'
import { COLOR } from '@farewill/ui/tokens'

import { WHAT_TO_DO_WHEN_SOMEONE_DIES_TELEPHONE_NUMBER } from 'config'

import DefaultLayout from 'layouts/DefaultLayout'
import Hero from './Hero'
import Steps from './Steps'
import Checklist from './Checklist'
import Cruse from './Cruse'
import Testimonial from './Testimonial'

const WhatToDoWhenSomeoneDies = () => (
  <DefaultLayout
    title="What to do when someone dies | Five simple steps"
    description="Download and print our free checklist so you can keep track of everything you need to do after someone dies."
    headerColor={COLOR.WHITE}
    telephoneNumber={WHAT_TO_DO_WHEN_SOMEONE_DIES_TELEPHONE_NUMBER}
  >
    <Hero />
    <Steps />
    <Checklist />
    <Cruse />
    <Testimonial />
  </DefaultLayout>
)

export default WhatToDoWhenSomeoneDies
