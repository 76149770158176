import React from 'react'
import styled from 'styled-components'
import { Grid, H, P, Image, Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import formatTelephoneNumber from 'lib/formatting/telephoneNumber'

import ExternalLink from 'components/ExternalLink'

const cruseLink = 'https://www.cruse.org.uk'
const cruseTelephoneNumber = '08088081677'

const StyledImageGridItem = styled(Grid.Item)`
  align-self: center;
  justify-self: center;
`

const StyledImage = styled(Image)`
  max-width: 100%;
`

const Cruse = () => (
  <Wrapper background={COLOR.WHITE}>
    <Grid container gapFromM="XL">
      <StyledImageGridItem spanFromM={5}>
        <StyledImage
          path="external-logos/cruse-bereavement-care"
          width={200}
          widthFromM={320}
        />
      </StyledImageGridItem>
      <Grid.Item spanFromM={7} spanFromL={6}>
        <H size="M">Bereavement support with Cruse</H>
        <P>
          We understand that losing a loved one is never easy, but we hope this
          checklist helps to take some of the weight off your shoulders. If
          you’re finding this time particularly difficult, you can get support
          from{' '}
          <ExternalLink href={cruseLink}>Cruse Bereavement Care</ExternalLink>{' '}
          by calling their free helpline on{' '}
          <a href={`tel:${cruseTelephoneNumber}`} rel="nofollow">
            {formatTelephoneNumber(cruseTelephoneNumber)}
          </a>
          .
        </P>
      </Grid.Item>
    </Grid>
  </Wrapper>
)

export default Cruse
